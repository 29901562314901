.mainWrapper {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 30px);
  top: 30px;
  left: 0;
  .title {
    text-align: center;
    margin-top: 180px;
  }
  .name {
    text-align: center;
    margin-top: 300px;
  }
  .nextBtn {
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 40px;
    width: 80px;
    box-shadow: 0 2px 4px rgb(233, 212, 233);
    background-color: rgb(240, 233, 238);
    border: none;
    border-radius: 4px;
  }
}

.letterWrapper {
  width: 100vw;
  height: calc(100vh - 30px);
  position: fixed;
  overflow-y: scroll;
  top: 30px;
  left: 0;
  .letter {
    .title {
      text-align: center;
      color: #6733d5;
      font-size: 36px;
    }
    .content {
      margin: 20px;
      display: grid;
      grid-template-columns: 100px 1fr;
      h3 {
        color: #ae8fef;
        text-align: center;
      }
      span {
        min-height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
      hr {
        border-top: 1px solid #30014c;
        width: 100%;
      }
    }
  }
}

.contentsWrapper {
  width: 100vw;
  height: calc(100vh - 30px);
  position: fixed;
  top: 30px;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  .sectionWrapper {
    width: 50vw;
    height: calc(50vh - 15px);
    position: relative;
    .section {
      position: absolute;
      width: 30vw;
      height: calc(30vh - 9px);
      left: 10vw;
      top: calc(10vh - 3px);
      text-align: center;
      line-height: calc(30vh - 39px);
      border: 1px solid #ae8fef;
      border-radius: 12px;
      box-shadow: 0 2px 4px #ae8fef;
      font-size: 16px;
      font-weight: bold;
      background-color: #fff;
    }
  }
}

.hide {
  display: none;
}

.goBackBtnWrapper {
  width: 100%;
  height: 30px;
  .goBackBtn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    background-color: transparent;
    border: none;
  }
}

.letter4Wrapper {
  width: calc(100vw - 40px);
  height: calc(100vh - 70px);
  position: fixed;
  top: 30px;
  left: 0;
  padding: 20px;
  .letterLine {
    line-height: 28px;
    font-size: 14px;
  }
  .buttonWrapper {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-around;
    button {
      width: 30vw;
      height: 30vw;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      border: none;
      box-shadow: 0px 2px 4px #6733d5;
      &.leftBtn {
        background-image: url('./assets/left.jpeg');
      }
      &.rightBtn {
        background-image: url('./assets/right.jpeg');
      }
    }
  }
}
